package widgets.StudentDuoSlider.ui

import app.useAppDispatch
import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.EExerciseState
import pages.studentDuo.SetDuoCourses
import pages.studentDuo.SetDuoCoursesWereFetched
import pages.studentDuo.selectStudentDuoCourses
import react.VFC
import react.redux.useSelector
import react.router.useNavigate
import react.router.useParams
import shared.components.ProtectedRoute.ProtectedRoute

val StudentDuoSliderFromCourse = VFC {
    val pathParams = useParams()
    val navigate = useNavigate()
    val dispatch = useAppDispatch()

    val folderId = pathParams["folder_id"]?.toIntOrNull()
    val requestUuid = pathParams["request_uuid"]
    val courses = useSelector(selectStudentDuoCourses)

    ProtectedRoute {
        toStudentDuo = true
        StudentDuoSlider {
            showBackButton = true
            onContinueClick = {
                navigate("/duo/folder/$folderId")
            }
            onSolved = f@{
                val currentExercise = courses.find { it.id == folderId }?.states?.find {
                    it.uuid == requestUuid
                }
                if (currentExercise == null) {
                    navigate("/duo/folder/${folderId}")
                    return@f
                }
                if (currentExercise.state == EExerciseState.SOLVED.value) {
                    return@f
                }

                val newCourses = courses.map {
                    if (it.id != folderId) return@map it

                    val newStates = it.states.map states@{ exercise ->
                        if (exercise.uuid != requestUuid) return@states exercise

                        exercise.copy(state = EExerciseState.SOLVED.value)
                    }.toMutableList()
                    if (currentExercise.state == EExerciseState.CURRENT.value) {
                        newStates.firstOrNull { exercise ->
                            exercise.state == EExerciseState.NOT_SOLVED.value
                        }?.state = EExerciseState.CURRENT.value
                    }

                    it.copy(
                        states = newStates
                    )
                }

                localStorage.setItem("duo_courses", Json.encodeToString(newCourses))
                dispatch(SetDuoCourses(newCourses))
            }
        }
    }
}
