package online.interactiver.common.enums

enum class ELanguage(val text: String, val code: String, val align: String, val icon: String) {
    ENGLISH("English", "en-US", "Left", "\uD83C\uDDEC\uD83C\uDDE7"),
    SPANISH("Spanish", "es-ES", "Left", "\uD83C\uDDEA\uD83C\uDDF8"),
    RUSSIAN("Russian", "ru-RU", "Left", "\uD83C\uDDF7\uD83C\uDDFA"),
    CHINESE("Chinese", "cmn-CN", "Left", "\uD83C\uDDE8\uD83C\uDDF3"),
    ARABIC("Arabic", "ar-XA", "Right", "\uD83C\uDDF8\uD83C\uDDE6"),
    FRENCH("French", "fr-FR", "Left", "\uD83C\uDDEB\uD83C\uDDF7"),
    GERMAN("German", "de-DE", "Left", "\uD83C\uDDE9\uD83C\uDDEA"),
    GREEK("Greek", "el-GR", "Left", "\uD83C\uDDEC\uD83C\uDDF7"),
    HEBREW("Hebrew", "he-IL", "Right", "\uD83C\uDDEE\uD83C\uDDF1"),
    ITALIAN("Italian", "it-IT", "Left", "\uD83C\uDDEE\uD83C\uDDF9"),
    JAPANESE("Japanese", "ja-JP", "Left", "\uD83C\uDDEF\uD83C\uDDF5"),
    KOREAN("Korean", "ko-KR", "Left", "\uD83C\uDDF0\uD83C\uDDF7"),
    POLISH("Polish", "pl-PL", "Left", "\uD83C\uDDF5\uD83C\uDDF1"),
    PORTUGUESE("Portuguese", "pt-PT", "Left", "\uD83C\uDDF5\uD83C\uDDF9"),
    SERBIAN("Serbian", "sr-RS", "Left", "\uD83C\uDDF7\uD83C\uDDF8"),
    TURKISH("Turkish", "tr-TR", "Left", "\uD83C\uDDF9\uD83C\uDDF7"),
    UKRAINIAN("Ukrainian", "uk-UA", "Left", "\uD83C\uDDFA\uD83C\uDDE6");

    override fun toString(): String {
        return text
    }

    companion object {
        val icons = entries.map { it.icon }

        fun fromText(text: String?): ELanguage? {
            return ELanguage.entries.find { it.text == text }
        }
    }
}
