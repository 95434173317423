package online.interactiver.common.autogeneration.taskLineContent

import online.interactiver.common.interactiveexercise.ExerciseVocabularyToLearn
import online.interactiver.common.interactiveexercise.VocabularyToLearnSelectorOption

class BuildSentenceContent(
    // phrase with exercise options
    //  we are [go / going* / went / gone] to a cinema with [*/a/an] friends
    //  patternedPhrase = "we are {going} to a cinema with {×} friends"; [Vocabulary(go / going* / went / gone); Vocabulary(×*/a/an)]
    //  Sentence with task words selected [in*/at/on] [brackets*/curves/circles]
    var patternedPhrase: String,
    // hint or translation stored here if exists
    var hint: String,
    var soundSrc: String? = null
) : TaskLineContent {
    override fun toRawTaskContent(): String = "$patternedPhrase${if (hint.isNotBlank()) ";$hint" else ""}"

    private var finalOptions: MutableList<ExerciseVocabularyToLearn> = mutableListOf()
    private var finalPatternedWords: MutableList<String> = mutableListOf()
    private var sentenceToSoundWords: MutableList<String> = mutableListOf()
    private var correctPhrase: String? = null
    fun getCorrectPhrase(): String {
        if (correctPhrase == null) {
            parsePatternedPhrase()
        }
        return correctPhrase!!
    }

    fun getFinalOptions(): MutableList<ExerciseVocabularyToLearn> {
        if (finalOptions.isEmpty()) {
            parsePatternedPhrase()
        }
        return finalOptions
    }

    fun getFinalPatternedWords(): MutableList<String> {
        if (finalPatternedWords.isEmpty()) {
            parsePatternedPhrase()
        }
        return finalPatternedWords
    }

    fun getSentenceToSoundWords(): MutableList<String> {
        if (sentenceToSoundWords.isEmpty()) {
            parsePatternedPhrase()
        }
        return sentenceToSoundWords
    }

    fun parsePatternedPhrase() {
        val sentenceWordsBuilder = mutableListOf<StringBuilder>(StringBuilder())
        var openedBracket = false
        for (c in patternedPhrase) {
            if (c == '{') {
                openedBracket = true
                sentenceWordsBuilder.add(StringBuilder(c.toString()))
            } else if (c == '}') {
                openedBracket = false
                sentenceWordsBuilder.lastOrNull()?.append(c)
                sentenceWordsBuilder.add(StringBuilder())
            } else if (c.isWhitespace()) {
                if (openedBracket) {
                    sentenceWordsBuilder.lastOrNull()?.append(c)
                } else {
                    sentenceWordsBuilder.add(StringBuilder())
                }
            } else {
                sentenceWordsBuilder.lastOrNull()?.append(c)
            }
        }

        val sentenceWords = sentenceWordsBuilder.map { it.toString() }.filter { it.isNotBlank() }.toList()

        for (word in sentenceWords) {
            if (word.startsWith('{') && word.endsWith('}')) {
                val options = word.subSequence(1, word.lastIndex).split("/").map {
                    var option = it.trim()
                    var isCorrect = "0"
                    if (option.lastOrNull() == '*') {
                        isCorrect = "1"
                        option = option.substring(0, option.lastIndex)
                    }
                    VocabularyToLearnSelectorOption(option, isCorrect)
                }
                val firstCorrectOption = options.firstOrNull { it.isCorrect == "1" } ?: options.firstOrNull()
                ?: VocabularyToLearnSelectorOption("×", "1")
                finalPatternedWords.add("{${firstCorrectOption.value}}")
                sentenceToSoundWords.add(firstCorrectOption.value)
                finalOptions.add(
                    ExerciseVocabularyToLearn(
                        value = firstCorrectOption.value,
                        finalOptions = options.toMutableList()
                    )
                )
            } else {
                finalPatternedWords.add(word)
                sentenceToSoundWords.add(word)
            }
        }

        correctPhrase = sentenceToSoundWords.joinToString(" ")
    }

    // get from GPT "phrase with exercise options;correct phrase;" and vocabulary. try to not put selectors to the end

    // parser to template (в build slider)
    // Собирается List<ExerciseVocabularyToLearn> и все нужное для передачи в параметризацию (в buildSlider)

    // TODO: набор функций которые проверяют правильность строки и возвращают ошибки пользователю: "не закрыта фигурная скобка открытая на символе Х"
    // is correct  в целом
    // may be selector (each gap contains more than one option)
    // may be puzzles (more than one gap)
    // have enough place for tasktype (by size given that all length is shorter 200)

    // to front / to back  (from '{}' to '[]' and back)
    fun getPatternedPhraseToUserUI() = patternedPhrase.replace('{', '[').replace('}', ']')


    // TODO: if you need to cumulate vocabulary to learn also from Build-Sentence content, implement getVocabularyToLearn()

    fun hasHint(): Boolean = hint.isNotBlank()
}

fun patternedPhraseFromUserUI(patternedPhrase: String) = patternedPhrase.replace('[', '{').replace(']', '}')
