package features.PromoCodePopup

import csstype.*
import utils.types.CssStyle

val popup: (Boolean) -> CssStyle = { fromLanguageAuto ->
    {
        position = Position.sticky
        bottom = 0.px
        display = Display.flex
        if (!fromLanguageAuto) {
            flexDirection = FlexDirection.column
            maxWidth = 414.px
        } else {
            justifyContent = JustifyContent.spaceEvenly
        }
        alignItems = AlignItems.center
        gap = 10.px
        backgroundColor = Color("#191613")
        padding = 20.px
        boxSizing = BoxSizing.borderBox
        width = 100.pct
        borderTopRightRadius = 10.px
        borderTopLeftRadius = 10.px
    }
}

val icon: CssStyle = {
    width = 120.px
    height = 120.px
}

val label: CssStyle = {
    color = Color("white")
    margin = 0.px
    fontSize = 1.2.rem
}

val offer: CssStyle = {
    color = Color("white")
    margin = 0.px
    fontSize = 1.2.rem
}

val closeButton: CssStyle = {
    position = Position.absolute
    border = None.none
    top = 5.px
    right = 5.px
    hover {
        scale = "1.2".unsafeCast<Scale>()
    }
    backgroundColor = "inherit".unsafeCast<BackgroundColor>()
    padding = 5.px
}

val text: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 10.px
}
