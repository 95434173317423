package online.interactiver.common.course

import kotlinx.serialization.Serializable
import online.interactiver.common.enums.ECourseLevel

val defaultNumberOfAppearances = ECourseLevel.REPEAT.timesToAppearInCourse

@Serializable
data class MaterialToGenerateExercises(
    var value: String = "",
    var definition: String = "",
    var numberOfAppearances: Int = defaultNumberOfAppearances,
    var audioSrc: String? = null,
    var imageSrc: String? = null,
    var id: Int,
)
