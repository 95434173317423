package online.interactiver.common.interactivepicture

import kotlinx.serialization.Serializable
import online.interactiver.common.math.LinearTransformation

@Serializable
data class GeometryStyle(
    var cornerRadius: Int? = null,

    var strokeColor: String? = null,
    var strokeWidth: Int? = null,

    var lineCap: String? = null,
    var lineJoin: String? = null,
    var dash: MutableList<Double>? = null, // Konva sample "[29, 20, 0.001, 20]"

    var fillColor: String? = null,
    var opacity: Double? = null,

    var shadowColor: String? = null,
    var shadowBlur: Int? = null,
    var shadowOffset: Point? = null,
    var shadowOpacity: Double? = null,

    var mouseCursor: String? = null,

    //var globalCompositeOperation: String? = null, // for effect when drag? // low priority

    //maybe add later:
    //var pointerDirection: String? = null, // "down", "left"
    //var pointerWidth: Int? = null,
    //var pointerHeight: Int? = null,

    //var animation on hover: ? = null,
    //var animation per time: ? = null, // to highlight something, hints for example
) {
    fun clone() = copy()
    fun applyTransformation(linearTransformation: LinearTransformation) {
        cornerRadius = linearTransformation.applyToWidth(cornerRadius)
    }
}

@Serializable
data class ElementStyle(
    var usual: GeometryStyle? = null,
    var onHover: GeometryStyle? = usual, // only on desktop
    var onFocus: GeometryStyle? = usual,
    var onSelect: GeometryStyle? = onFocus, // pressed button after focus released // low priority
    var onDrag: GeometryStyle? = null,

    var correctSelected: GeometryStyle? = null,
    var wrongSelected: GeometryStyle? = null,
    var correctUnselected: GeometryStyle? = null,
    var wrongUnselected: GeometryStyle? = null,
) {
    fun clone() = copy(
        usual = usual?.clone(),
        onHover = onHover?.clone(),
        onFocus = onFocus?.clone(),
        onSelect = onSelect?.clone(),
        onDrag = onDrag?.clone(),
        correctSelected = correctSelected?.clone(),
        wrongSelected = wrongSelected?.clone(),
        correctUnselected = correctUnselected?.clone(),
        wrongUnselected = wrongUnselected?.clone(),
    )
    fun applyTransformation(linearTransformation: LinearTransformation) {
        usual?.applyTransformation(linearTransformation)
        onHover?.applyTransformation(linearTransformation)
        onFocus?.applyTransformation(linearTransformation)
        onSelect?.applyTransformation(linearTransformation)
        onDrag?.applyTransformation(linearTransformation)
        correctSelected?.applyTransformation(linearTransformation)
        wrongSelected?.applyTransformation(linearTransformation)
        correctUnselected?.applyTransformation(linearTransformation)
        wrongUnselected?.applyTransformation(linearTransformation)
    }

}

@Serializable
data class TextStyle(
    var fontFamily: String? = null,
    var fontSize: Int? = null,
    var fontStyle: String? = null,
    var textColor: String? = null,

    var padding: Int? = 20,
    var align: String? = "left", // 'left', 'center', 'right' or 'justify'
    var verticalAlign: String? = "middle",
    var textDecoration: String? = "none",
    var lineHeight: Double? = 1.0,
    //maybe add later:
    //var selectable: String? = null,
    //var copyable: String? = null,
) {
    fun clone() = copy()

    fun applyTransformation(linearTransformation: LinearTransformation) {
        fontSize = linearTransformation.applyToHeight(fontSize)
        padding = linearTransformation.applyToWidth(padding)
    }
}

@Serializable
data class Style(
    // depends on library we will choose
    var defaultText: TextStyle? = null,

    var background: ElementStyle? = null,

    var frame: ElementStyle? = null,
    var staticFigure: ElementStyle? = null,
    var staticLine: ElementStyle? = null,

    var button: ElementStyle? = null,
    var selector: ElementStyle? = null,

    var inputGeometry: ElementStyle? = null,
    var inputText: TextStyle? = null,

    var drag: ElementStyle? = null,

    var hint: ElementStyle? = null,
    var hintText: TextStyle? = null,

    var checkButton: ElementStyle? = null,
    var checkButtonText: TextStyle? = null,
    var restartButton: ElementStyle? = null,
    var restartButtonText: TextStyle? = null,
) {
    fun clone() = copy(
        defaultText = defaultText?.clone(),
        background = background?.clone(),
        frame = frame?.clone(),
        staticFigure = staticFigure?.clone(),
        staticLine = staticLine?.clone(),
        button = button?.clone(),
        selector = selector?.clone(),
        inputGeometry = inputGeometry?.clone(),
        inputText = inputText?.clone(),
        drag = drag?.clone(),
        hint = hint?.clone(),
        hintText = hintText?.clone(),
    )
    fun applyTransformation(linearTransformation: LinearTransformation) {
        background?.applyTransformation(linearTransformation)
    }
}

@Serializable
data class PhraseStyle(
    var lineSpacing: Int = 10,
    var elementPadding: Int = 8,
    var elementSpacing: Int = 4,
    var elementsAlign: String = "Left",
    var fillColor: String = "#E0E8FC",
    var puzzlesColor: String = "#9DACF3",
    var lineElementGap: Int = 2,
    var lineHeight: Int = 2,
    var elementHeight: Int = 26,
    var lineSpacingForUnplacedPuzzles: Int = 3,
    var fontSize: Int = 14
)
