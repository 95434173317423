package widgets.LanguageAutoContent.ui.components.SelectLanguage

import antd.Option
import antd.Select
import csstype.*
import emotion.react.css
import online.interactiver.common.enums.ELanguage
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import shared.components.Icon
import shared.components.selectWithLabel.label
import shared.components.selectWithLabel.verticalContainer
import utils.preloadIcons

external interface SelectLanguageProps : Props {
    var label: String
    var options: List<String>
    var onChange: (String) -> Unit
    var value: String?
    var makeRed: Boolean?
}

val SelectLanguage = FC<SelectLanguageProps> {
    div {
        css(verticalContainer)
        p {
            css(label)
            +it.label
        }
        Select {
            if (it.makeRed == true) {
                css {
                    border = Border(1.px, LineStyle.solid, Color("red"))
                    borderRadius = 3.px
                }
            }
            placeholder = "Select language"
            showSearch = true
            value = it.value
            onChange = { _, option ->
                it.onChange(option.value.toString())
            }
            it.options.forEach { option ->
                Option {
                    value = option
                    ELanguage.entries.firstOrNull { it.text == option && it.icon.isNotBlank() }?.let {
                        span {
                            +it.icon
                        }
                    }
                    +option
                }
            }
        }
    }
}
