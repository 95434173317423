package pages.studentDuoRoadMap.ui.ExerciseButton

import csstype.*
import utils.phones
import utils.types.CssStyle

val link: (MarginLeft) -> CssStyle = {
    {
        width = 19.8.pct
        padding = 0.1.em
        boxSizing = BoxSizing.borderBox
        borderRadius = 8.px
        fontSize = 3.5.rem
        lineHeight = number(1.0)
        fontWeight = FontWeight.bold
        textAlign = TextAlign.center
        textDecoration = None.none
        margin = 0.px
        marginLeft = it
        phones {
            fontSize = 3.rem
        }
    }
}

val solved: CssStyle = {
    backgroundColor = Color("#daf1e1")
    color = Color("#5da774")
    hover {
        scale = "1.1".unsafeCast<Scale>()
    }
}

val unsolved: CssStyle = {
    backgroundColor = Color("#edeffd")
    color = Color("#5d6676")
    hover {
        scale = "1.1".unsafeCast<Scale>()
    }
}

val disabled: CssStyle = {
    backgroundColor = Color("#edeffd")
    color = Color("#5d6676")
}

val current: CssStyle = {
    backgroundColor = Color("#597EF7")
    color = Color("white")
    position = Position.relative
    hover {
        scale = "1.1".unsafeCast<Scale>()
    }
}

val currentIcon: CssStyle = {
    position = Position.absolute
    left = (-40).px
    top = 0.px
    display = Display.flex
    alignItems = AlignItems.center
    height = 100.pct
}
