package widgets.DuoCourses.ui.DuoCourses

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    paddingBottom = 0.px
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
}

val coursesStyle: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    maxHeight = 300.px
    overflowY = Auto.auto
}

val buttonsContainer: CssStyle = {
    margin = Margin(0.px, Auto.auto)
}

val noCourses: CssStyle = {
    fontSize = 1.25.rem
    lineHeight = 130.pct
    margin = Margin(0.px, Auto.auto)
    padding = 0.px
    color = Color("#282F3E")
    fontWeight = FontWeight.bold
}
