package widgets.LanguageAutoTask.ui.components.HiddenIframe

val screeningImagesScript = """
    const images = [];
    const numberOfSlides = multitaskContext.interactivePictures.length;
    
    const handleOnScreen = (event) => {
        subscribeImageLoadListener(multitaskContext.currentInteractiveContext, () => {
            images.push({
                image: multitaskContext.currentInteractiveContext.stage.toDataURL(),
                taskLine: multitaskContext.currentInteractiveContext.interactivePicture.taskToGenerateSlide
            });
            if (event.detail.index == numberOfSlides) {
                window.parent.postMessage(images, '*');
                document.removeEventListener("onScreen", handleOnScreen);
            } else {
                goToInteractive(multitaskContext, multitaskContext.currentInteractiveIndex + 1);
                document.dispatchEvent(new CustomEvent("onScreen", {
                    detail: { index: event.detail.index + 1 }
                }));
            }
        });
    };
    
    document.addEventListener("onScreen", handleOnScreen);
    goToInteractive(multitaskContext, 0);
    
    document.dispatchEvent(new CustomEvent("onScreen", {
        detail: { index: 1 }
    }));
""".trimIndent()

val hideControlsButtonsForOneSlide = { index: Int ->
    """
        const controlButtonsContainer = document.getElementById('bottom-controls-container' + multitaskContext.suffix);
        if (controlButtonsContainer) {
            controlButtonsContainer.style.display = "none";
        }
        if ($index !== multitaskContext.currentInteractiveIndex) {
            goToInteractive(multitaskContext, $index);
        }
    """.trimIndent()
}

val trackSlideIndexScript = """
    window.parent.postMessage(multitaskContext.currentInteractiveIndex, '*');
    document.addEventListener('onGoToInteractive', event => {
        window.parent.postMessage(event.detail.index, '*');
    });
""".trimIndent()

val cookieScript = """
    const studentNameContainer = document.getElementById("student-name-container");
    if (studentNameContainer) {
        studentNameContainer.id = "none";
    }
    
    const cookieContainer = document.getElementById(`cookie-container`);
    if (cookieContainer) {
        cookieContainer.style.display = "none";
    }
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("saveProgressToLocalStorage") != "0") {
        searchParams.set("saveProgressToLocalStorage", "0");
        searchParams.set("noGamifiedPopups", "1");
        searchParams.set("doNotTrackStatsForActionableFeedback", "1");
        window.location.search = searchParams;
    }
""".trimIndent()

val duoScript = """
    const cookieContainer = document.getElementById(`cookie-container`);
    if (cookieContainer) {
        cookieContainer.style.display = "none";
    }
    logging.continueButtonOnPlatform = "1"
    
    document.addEventListener("onContinuePlatformButtonClick", () => {
        window.parent.postMessage({ event: "onContinuePlatformButtonClick" }, '*');
    });
    
    document.addEventListener("onSolved", () => {
        window.parent.postMessage({ event: "onSolved", value: true }, '*');
    });
""".trimIndent()

fun goToSlideScript(index: Int) = """
    if ($index !== multitaskContext.currentInteractiveIndex) {
        goToInteractive(multitaskContext, $index, true);
    }
""".trimIndent()

fun getIframeWithCookieScript(slider: String, script: String): String {
    val res = getIframeSrc(slider, script)
    val delimiter = if (slider.contains("handleCookiesWarning();")) "handleCookiesWarning();" else "try {"
    return res.substringBeforeLast(delimiter) +
            delimiter +
            cookieScript +
            res.substringAfterLast(delimiter)
}

fun getIframeWithDuoScript(slider: String): String {
    val delimiter = if (slider.contains("handleCookiesWarning();")) "handleCookiesWarning();" else "try {"
    return slider.substringBeforeLast(delimiter) +
            delimiter +
            duoScript +
            slider.substringAfterLast(delimiter)
}

fun getIframeSrc(slider: String, script: String) : String {
    val delimiter = "multitaskContext;"
    return slider.substringBeforeLast(delimiter) +
            delimiter +
            script +
            slider.substringAfterLast(delimiter)
}