package widgets.DuoCourses.ui.DuoCourse

import csstype.*
import utils.types.CssStyle

val course: CssStyle = {
    padding = Padding(11.px, 18.px, 16.px, 20.px)
    boxSizing = BoxSizing.borderBox
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 9.px
    backgroundColor = Color("#f4f7ff")
    borderRadius = 8.px
    cursor = Cursor.pointer
}

val name: CssStyle = {
    overflow = Overflow.hidden
    textOverflow = TextOverflow.ellipsis
    maxWidth = 190.px
    margin = 0.px
    color = Color("#1d39c4")
    fontSize = 1.25.rem
    lineHeight = 130.pct
    fontWeight = FontWeight.bold
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 9.px
}

val container: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.flexStart
    justifyContent = JustifyContent.spaceBetween
    gap = 50.px
}

val bar: CssStyle = {
    width = 100.pct
    backgroundColor = Color("#d0d5dd")
    height = 16.px
    borderRadius = 8.px
}

val progress: (Double) -> CssStyle = { percent ->
    {
        width = percent.pct
        height = 100.pct
        backgroundColor = Color("#5da774")
        borderRadius = "inherit".unsafeCast<BorderRadius>()
    }
}

val iconButton: CssStyle = {
    width = 32.px
    height = 32.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    padding = 0.px
}
