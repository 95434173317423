package widgets.UserProfile

import ApiResponse
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.user.UpdateUserRequest
import utils.getToken

suspend fun updateNameRequest(newName: String): ApiResponse<UpdateUserRequest> {
    return try {
        val response = jsonClient.put("api/v1/user-account/update-name") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(UpdateUserRequest(newName))
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}

suspend fun updateAboutRequest(newAbout: String): ApiResponse<UpdateUserRequest> {
    return try {
        val response = jsonClient.put("api/v1/user-account/update-about") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
            setBody(UpdateUserRequest(newAbout))
        }

        if (response.status.value != 200) {
            return ApiResponse(null, response.status.value)
        }

        ApiResponse(Json.decodeFromString(response.bodyAsText()), response.status.value)
    } catch (e: ResponseException) {
        ApiResponse(null, e.response.status.value)
    }
}
