package entities.modalLoader

import kotlinx.js.timers.clearTimeout
import kotlinx.js.timers.setTimeout
import react.useEffect
import react.useState

data class EntertainingTextsMeta(val value: String, val duration: Int, val nextIndex: Int? = null)

val entertainingTexts = listOf(
    EntertainingTextsMeta("Starting AI magic ✨", 3),
    EntertainingTextsMeta("Analyzing your preferences \uD83D\uDD0D", 3),
    EntertainingTextsMeta("Generating content for exercises ✍\uFE0F", 7),
    EntertainingTextsMeta("Crafting gamified exercises \uD83E\uDD57", 7),
    EntertainingTextsMeta("Generating sound \uD83D\uDD0A", 7),
    EntertainingTextsMeta("Almost done \uD83D\uDD1C", 10),
    EntertainingTextsMeta("Verifying Exercise Accuracy ✅", 10),
    EntertainingTextsMeta("Fixing Exercise Accuracy \uD83D\uDCDD", 8),
    EntertainingTextsMeta("Almost there \uD83D\uDD52", 10),
    EntertainingTextsMeta("Testing Exercise Flow \uD83D\uDD03", 7),
    EntertainingTextsMeta("Fine-Tuning for Perfection \uD83D\uDEE0", 7),
    EntertainingTextsMeta("The last action ☺\uFE0F", 10),
    EntertainingTextsMeta("Identifying Tweaks Needed ⚠\uFE0F", 10),
    EntertainingTextsMeta("Making Adjustments \uD83D\uDD27",10),
    EntertainingTextsMeta("Fine-Tuning for Perfection \uD83D\uDEE0", 10),
    EntertainingTextsMeta("Preparing for Launch \uD83D\uDE80", 10, 12)
)

data class ModalLoaderWithEntertainingTexts(val startLoading: () -> Unit, val endLoading: () -> Unit)

fun useModalLoaderWithEntertainingTexts(startLoading: (String) -> Unit, endLoading: () -> Unit): ModalLoaderWithEntertainingTexts {
    val (indexOfEntertainingText, setIndexOfEntertainingText) = useState<Int?>(null)

    useEffect(indexOfEntertainingText) {
        if (indexOfEntertainingText == null) {
            return@useEffect
        }

        val id = setTimeout({
            val newIndex = entertainingTexts[indexOfEntertainingText].nextIndex
                ?: ((indexOfEntertainingText + 1) % entertainingTexts.size)

            setIndexOfEntertainingText(newIndex)
            startLoading(entertainingTexts[newIndex].value)
        }, entertainingTexts[indexOfEntertainingText].duration * 1000)

        cleanup {
            clearTimeout(id)
        }
    }

    return ModalLoaderWithEntertainingTexts(
        startLoading = {
            setIndexOfEntertainingText(0)
            startLoading(entertainingTexts[0].value)
        },
        endLoading = {
            setIndexOfEntertainingText(null)
            endLoading()
        }
    )
}
