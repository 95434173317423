package widgets.StudentDuoContent.ui.TopicTextArea

import antd.TextArea
import builders.enums.EAlign
import emotion.react.css
import enums.EButtonTypes
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.html.ReactHTML.div
import shared.components.inputLabelledLimited.Counter
import widgets.LanguageAutoContent.ui.components.Content.maxContentText

external interface TopicTextAreaProps : Props {
    var value: String?
    var onChange: ((String) -> Unit)?
}

val TopicTextArea = FC<TopicTextAreaProps> { props ->
    val (value, setValue) = useState(props.value)

    val limit = maxContentText
    useEffect(props.value) {
        setValue(props.value)
    }

    div {
        css(container)
        div {
            css(labelContainer)
            div {
                div {
                    css(label)
                    +"Write topic, words or text on any language"
                }
            }
            Counter {
                this.limit = limit
                this.value = value?.length
                this.align = EAlign.Horizontal.RIGHT
            }
        }
        TextArea {
            id = EButtonTypes.DUO_CONTENT_TEXTAREA.value
            css(textArea)
            placeholder = "e.g. \"I want to raise my salary\", \"credit limit increase, future time\", \"dog, cat, rain, cloud, sun - simplest words\""
            this.value = value
            onBlur = {
                val value = it.target.value.take(limit)
                props.onChange?.invoke(value)
            }
            onChange = {
                setValue(it.currentTarget.value.take(limit))
            }
            dir = "auto"
        }
    }
}