package widgets.LanguageAutoContent.ui

import antd.Button
import app.useAppDispatch
import csstype.px
import emotion.react.css
import entities.errorModal.store.EErrorType
import entities.errorModal.store.OpenErrorModal
import entities.errorModal.store.RemoveErrorType
import entities.errorModal.store.selectErrorType
import entities.languageAutoCurrentRequest.*
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import online.interactiver.common.autogeneration.LanguageAutoState
import online.interactiver.common.enums.EAskStudentName
import online.interactiver.common.enums.EContentType
import online.interactiver.common.enums.ELanguage
import online.interactiver.common.enums.ELanguageLevel
import online.interactiver.common.utils.getName
import org.w3c.dom.get
import pages.languageAuto.*
import pages.languageAuto.ui.useLanguageAutoContext
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.dom.useSearchParams
import react.router.useParams
import shared.components.Icon
import shared.components.header.Header
import shared.components.selectWithLabel.SelectWithLabel
import widgets.Exercises.getLanguageAutoRequest
import widgets.LanguageAutoContent.countVocabulary
import widgets.LanguageAutoContent.generateTaskSliderRequest
import widgets.LanguageAutoContent.getFolder
import widgets.LanguageAutoContent.ui.components.Content.*
import widgets.LanguageAutoContent.ui.components.Content.ListeningContent.ListeningContent
import widgets.LanguageAutoContent.ui.components.Contents.Contents
import widgets.LanguageAutoContent.ui.components.SelectLanguage.SelectLanguage
import widgets.UserProfile.selectUserProfile

data class Defaults(
    val topic: String = "",
    val text: String = "",
    val vocabulary: String = ""
) {
    companion object {
        fun getDefaultIfTextOrTopicAndVocabularyIsBlank(
            default: String, textOrTopic: String, vocabulary: String
        ): String {
            return if (textOrTopic.isBlank() && vocabulary.isBlank()) default else ""
        }
        fun fromEContentType(contentType: EContentType?, textOrTopic: String, vocabulary: String): Defaults {
            return when (contentType) {
                EContentType.PREFERRED_TOPIC,
                EContentType.LISTENING_BY_TOPIC -> Defaults(
                    topic = getDefaultIfTextOrTopicAndVocabularyIsBlank(textPlaceholder, textOrTopic, vocabulary),
                    vocabulary = getDefaultIfTextOrTopicAndVocabularyIsBlank(vocabularyPlaceholder, textOrTopic, vocabulary)
                )
                EContentType.VOCABULARY_WORDS -> {
                    Defaults(
                        topic = getDefaultIfTextOrTopicAndVocabularyIsBlank(textPlaceholder, textOrTopic, vocabulary),
                        vocabulary = getDefaultIfTextOrTopicAndVocabularyIsBlank(vocabularyPlaceholder, textOrTopic, vocabulary)
                    )
                }
                EContentType.STORYTELLING -> {
                    Defaults(
                        topic = getDefaultIfTextOrTopicAndVocabularyIsBlank(storytellingTopicPlaceholder, textOrTopic, vocabulary),
                        text = getDefaultIfTextOrTopicAndVocabularyIsBlank(storytellingTextPlaceholder, textOrTopic, vocabulary)
                    )
                }
                EContentType.LISTENING_BY_TEXT -> Defaults(
                    text = getDefaultIfTextOrTopicAndVocabularyIsBlank(textPlaceholder, textOrTopic, vocabulary),
                    vocabulary = getDefaultIfTextOrTopicAndVocabularyIsBlank(vocabularyPlaceholder, textOrTopic, vocabulary)
                )
                EContentType.EXERCISES_FROM_CONTENT -> Defaults(
                    text = textPlaceholder
                )
                EContentType.GRAMMAR -> Defaults(
                    topic = grammarContentPlaceholder,
                )
                EContentType.PRE_MADE_CONTENT -> Defaults(
                    text = preMadeContentPlaceholder,
                )
                else -> Defaults()
            }
        }
    }
}

val LanguageAutoContent = FC<Props> {
    val languageToLearn = useSelector(selectCurrentRequestLanguageToLearn)
    val interfaceLanguage = useSelector(selectCurrentRequestInterfaceLanguage)
    val contentType = useSelector(selectCurrentRequestContentType)
    val languageLevel = useSelector(selectCurrentRequestLanguageLevel)
    val textOrTopic = useSelector(selectCurrentRequestTextOrTopic)
    val vocabulary = useSelector(selectCurrentRequestVocabulary)
    val translateRequestIntoTargetLang = useSelector(selectTranslateRequestToTargetLang)
    val numberOfSlidesToGenerate = useSelector(selectNumberOfSlidesToGenerate)
    val soundSrc = useSelector(selectCurrentRequestSoundSrc)

    val (entertainingLoader) = useLanguageAutoContext()

    val requestId = useSelector(selectCurrentRequestId)

    val errorType = useSelector(selectErrorType)

    val (scrollY, setScrollY) = useState<Double?>(null)
    val shouldMakeLanguageToLearnRed = errorType == EErrorType.EMPTY_LANGUAGE_TO_LEARN

    val shouldRegenerate = useSelector(selectShouldRegenerate)

    val dispatch = useAppDispatch()

    val (searchParams, setSearchParams) = useSearchParams()

    val parentFolderId = useMemo(searchParams) {
        searchParams.get("parent_folder_id")?.toIntOrNull()
    }
    val (parentFolderName, setParentFolderName) = useState<String?>(null)

    val userProfile = useSelector(selectUserProfile)

    val pathParams = useParams()
    val requestIdFromPathParam = useMemo(pathParams) {
        pathParams.get("request_id")?.toIntOrNull()
    }

    val (userHasNoAccessToExercise, setUserHasNoAccessToExercise) = useState(false)

    useEffect(requestIdFromPathParam) {
        if (requestIdFromPathParam == requestId) {
            return@useEffect
        }

        if (requestIdFromPathParam == null) {
            dispatch(UpdateCurrentLanguageAutoRequest(
                LanguageAutoState(
                    interfaceLanguage = localStorage["interface_language"] ?: ELanguage.ENGLISH.text,
                    languageToLearn = localStorage["language_to_learn"] ?: "",
                    languageLevel = localStorage["language_level"] ?: ELanguageLevel.ANY.value,
                    translateRequestToTargetLang = userProfile?.sliderSettings?.translateRequestToTargetLanguage ?: false,
                    numberOfSlidesToGenerate = userProfile?.sliderSettings?.numberOfSlidesToGenerate ?: 8
                )
            ))

            setUserHasNoAccessToExercise(false)

            return@useEffect
        }

        dispatch(StartModalLoading("Getting exercise..."))
        GlobalScope.launch {
            val request = getLanguageAutoRequest(requestIdFromPathParam, false)

            dispatch(EndModalLoading())
            if (request == null) {
                setUserHasNoAccessToExercise(true)
                return@launch
            }

            dispatch(SetHiddenIframeIsShown(true))
            dispatch(UpdateCurrentLanguageAutoRequest(request))
            setUserHasNoAccessToExercise(false)
        }
    }

    useEffect(parentFolderId) {
        if (parentFolderId == null) {
            setParentFolderName(null)
            return@useEffect
        }

        MainScope().launch {
            val folder = getFolder(parentFolderId).data
            folder?.let {
                setParentFolderName(it.name)
            }
        }
    }

    useEffectOnce {
        if (requestId != null || requestId == -1) { // if requestId == -1 then data was set from filters,
            // and we don't have to override it
            return@useEffectOnce
        }

        ELanguage.entries.firstOrNull {
            it.text == localStorage["language_to_learn"]
        }?.let { dispatch(SetCurrentRequestLanguageToLearn(it.text)) }

        ELanguage.entries.firstOrNull {
            it.text == localStorage["interface_language"]
        }?.let { dispatch(SetCurrentRequestInterfaceLanguage(it.text)) }

        ELanguageLevel.entries.firstOrNull {
            it.value == localStorage["language_level"]
        }?.let { dispatch(SetCurrentRequestLanguageLevel(it.value)) }
    }

    val regenerate: () -> Unit = f@{
        if (languageToLearn.isNullOrBlank()) {
            dispatch(OpenErrorModal(
                title = "Please select the language to learn",
                type = EErrorType.EMPTY_LANGUAGE_TO_LEARN
            ))
            return@f
        }

        if (contentType == EContentType.VOCABULARY_WORDS
            && countVocabulary(vocabulary) < minNumberOfVocabulary
            && vocabulary.isNotBlank()
            ) {
            dispatch(OpenErrorModal(
                title = "Please provide a minimum of $minNumberOfVocabulary words in the Target Vocabulary field",
                type = EErrorType.NOT_ENOUGH_VOCABULARY
            ))
            return@f
        }

        val defaults = Defaults.fromEContentType(contentType, textOrTopic, vocabulary)
        entertainingLoader.startLoading()
        GlobalScope.launch {
            val name = if (textOrTopic.isNotBlank()) {
                textOrTopic.getName(50)
            } else if (vocabulary.isNotBlank()) {
                vocabulary.getName(50)
            } else {
                "$languageToLearn on the $interfaceLanguage slider"
            }

            val (sliderResponse, error) = generateTaskSliderRequest(
                contentType?.value ?: "",
                textOrTopic.ifBlank { defaults.text.ifBlank { defaults.topic } },
                vocabulary.ifBlank { defaults.vocabulary },
                languageToLearn, interfaceLanguage, languageLevel,
                name = name,
                numberOfSlidesToGenerate = numberOfSlidesToGenerate,
                soundSrc = soundSrc,
                parentFolderId = parentFolderId
            )

            if (sliderResponse != null) {
                dispatch(SetHiddenIframeIsShown(true))
                dispatch(UpdateTaskInCurrentRequest(sliderResponse.task))
                dispatch(UpdateSliderInCurrentRequest(sliderResponse.slider))
                dispatch(SetCurrentRequestId(sliderResponse.requestId))
                dispatch(SetCurrentRequestName(sliderResponse.name))
                dispatch(UpdateCurrentRequestLink(sliderResponse.link!!, sliderResponse.embedCode!!))
                dispatch(SetCurrentRequestAskStudentName(EAskStudentName.DO_NOT_ASK.value))
                console.log("SliderCreationRequestId = '${sliderResponse.requestId}'")

                console.log("SELECT * FROM slider_auto_generation_lead_requests WHERE id = ${sliderResponse.requestId};")
            } else if (error != null) {
                entertainingLoader.endLoading()
                dispatch(OpenErrorModal(error.ifBlank {  "Try again" }))
            }
        }
    }

    useEffect(shouldRegenerate) {
        if (!shouldRegenerate) {
            return@useEffect
        }

        dispatch(SetShouldRegenerate(false))
        regenerate()
    }

    useLayoutEffect(contentType) {
        scrollY?.let { window.scrollTo(window.scrollX, it) }
    }

    if (userHasNoAccessToExercise) {
        section {
            css(widget)
            Header {
                this.text = "No access"
                this.fontSize = 20.0.px
                this.lineHeight = 27.0.px
            }
        }
        return@FC
    }

    section {
        css(widget)
        div {
            css(header)
            Icon {
                css {
                    width = 33.px
                    height = 30.px
                }
                src = "ic_interactive_38x35.svg"
            }
            parentFolderName?.let {
                div {
                    css(parentFolderNameHeaderLabelContainer)
                    Icon {
                        src = "ic_folder_28x28.svg"
                    }
                    div {
                        css(parentFolderNameHeaderLabel)
                        +it
                    }
                }
            }
        }
        Header {
            this.text = "1. Choose the language for your exercises"
            this.fontSize = 20.0.px
            this.lineHeight = 27.0.px
        }
        div {
            css(languages)
            div {
                css(toLearnAndLevelContainer)
                SelectLanguage {
                    label = "Language to learn"
                    value = languageToLearn
                    options = ELanguage.entries.map { it.text }
                    makeRed = shouldMakeLanguageToLearnRed
                    onChange = {
                        if (shouldMakeLanguageToLearnRed) {
                            dispatch(RemoveErrorType())
                        }

                        localStorage.setItem("language_to_learn", it)
                        dispatch(SetCurrentRequestLanguageToLearn(it))
                    }
                }
                SelectWithLabel {
                    label = "Language level"
                    value = languageLevel
                    options = ELanguageLevel.entries.map { it.value }
                    onChange = {
                        localStorage.setItem("language_level", it)
                        dispatch(SetCurrentRequestLanguageLevel(it))
                    }
                }
            }
            div {
                css {
                    width = 256.px
                }
                SelectLanguage {
                    label = "Interface language"
                    value = interfaceLanguage
                    options = ELanguage.entries.map { it.text }
                    onChange = {
                        localStorage.setItem("interface_language", it)
                        dispatch(SetCurrentRequestInterfaceLanguage(it))
                    }
                }
            }
        }
        Header {
            this.text = "2. Get interactive exercises for..."
            this.fontSize = 20.0.px
            this.lineHeight = 27.0.px
        }
        Contents {
            this.contentType = contentType?.value ?: ""
            this.onChange = { dispatch(SetCurrentRequestContentType(it)) }
            this.onScrollYChange = { setScrollY(it) }
        }

        when (contentType) {
            EContentType.EXERCISES_FROM_CONTENT -> ExercisesFromContent {}
            EContentType.STORYTELLING -> StorytellingContent {}
            EContentType.LISTENING_BY_TEXT -> ListeningContent {}
            EContentType.VOCABULARY_WORDS -> VocabularyContent {}
            EContentType.GRAMMAR -> GrammarContent {}
            EContentType.PRE_MADE_CONTENT -> PreMadeContent {}
            else -> {}
        }

        // This comment reverts develop/ff3d2377963098ea9cf5b95cdd82440c6fcb2a17
        //TranslateRequestToTargetLanguageCheckbox { }

        if (contentType == EContentType.LISTENING_BY_TEXT && textOrTopic.isBlank()) {
            return@section
        }

        Button {
            css(generateButton)
            Icon {
                src = "ic_magic_wand_24x24.svg"
            }
            onClick = {
                regenerate()
            }
            +"Do the magic!"
        }
    }
}
