package shared.components.ProtectedRoute

import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.jso
import pages.languageAuto.ui.ERoute
import react.*
import react.router.useLocation
import react.router.useNavigate
import utils.getToken
import utils.types.jsObject
import validateToken

external interface ProtectedRouteProps : PropsWithChildren {
    var toStudentDuo: Boolean?
}

val ProtectedRoute = FC<ProtectedRouteProps> { props ->
    val location = useLocation()
    val navigate = useNavigate()
    val token = getToken()

    useEffect(token, location.pathname) {
        GlobalScope.launch {
            val tokenValidation = validateToken()
            if (tokenValidation.code != 200) {
                val goTo = "${ERoute.GOOGLE_AUTH.path}${if (props.toStudentDuo == true) "?duo=${true}" else ""}"
                navigate(to = goTo, jso { state = jsObject { from = location.pathname } })
                return@launch
            }

            val previousPage = localStorage.getItem("previous_page")
            if (previousPage != null) {
                localStorage.removeItem("previous_page")
                navigate(to = previousPage)
            }
        }
    }

    +props.children
}