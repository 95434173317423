package features.GenerateCourseButton.ui

import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import entities.modalLoader.useModalLoaderWithEntertainingTexts
import features.GenerateCourseButton.generateCourseRequest
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.course.selectCourseState
import pages.languageAuto.ui.ERoute
import react.FC
import react.Props
import react.redux.useSelector
import react.router.dom.useSearchParams
import react.router.useNavigate
import shared.components.Icon
import widgets.LanguageAutoContent.ui.generateButton

val GenerateCourseButton = FC<Props> {
    val course = useSelector(selectCourseState)
    val dispatch = useAppDispatch()
    val navigate = useNavigate()
    val (searchParams) = useSearchParams()
    val parentFolderId = searchParams.get("parent_folder_id")?.toIntOrNull()

    val (startLoading, endLoading) = useModalLoaderWithEntertainingTexts({ dispatch(StartModalLoading(it))}) {
        dispatch(EndModalLoading())
    }

    Button {
        css(generateButton)
        onClick = {
            startLoading()
            GlobalScope.launch {
                val response = generateCourseRequest(course.copy(parentFolderId = parentFolderId))
                endLoading()
                if (response.data == null) {
                    dispatch(OpenErrorModal("Error generating course"))
                    return@launch
                }

                navigate("${ERoute.EXERCISES.path}?parent_folder_id=${response.data}")
            }
        }
        Icon {
            src = "ic_magic_wand_24x24.svg"
        }
        +"Do the magic"
    }
}
