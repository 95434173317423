package pages.languageAuto.ui

import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.CloseErrorModal
import entities.errorModal.ui.ErrorModal
import entities.modalLoader.EndModalLoading
import entities.modalLoader.ModalLoaderWithEntertainingTexts
import entities.modalLoader.StartModalLoading
import entities.modalLoader.useModalLoaderWithEntertainingTexts
import entities.viewport.ViewportContext
import entities.viewport.useViewport
import features.PromoCodePopup.PromoCodePopup
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.constructor.ui.components.modalLoader.LanguageAutoModalLoader
import pages.course.ui.Course
import pages.languageAuto.*
import react.*
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.Route
import react.router.Routes
import react.router.useNavigate
import shared.components.ProtectedRoute.ProtectedRoute
import utils.checkAuth
import validateToken
import widgets.AdminPanel.ui.ResponsibleAdminPanel
import widgets.ExerciseSettings.ExerciseSettings
import widgets.Exercises.ui.PrivateExercises
import widgets.Exercises.ui.PublicExercises
import widgets.Exercises.ui.SharedExercises
import widgets.LanguageAutoContent.ui.LanguageAutoContent
import widgets.LanguageAutoSlider.ui.LanguageAutoSlider
import widgets.SliderStatistics.ui.SliderStatistics
import widgets.UserProfile.SetUserProfile
import widgets.UserProfile.ui.UserProfileToEdit
import widgets.UserProfile.ui.UserProfileToView

enum class ERoute(val path: String) {
    EXERCISES("/exercises"),
    EXERCISE("/generate"),
    SETTINGS("/settings"),
    GOOGLE_AUTH("/google-auth"),
    COMMUNITY("/community"),
    PROFILE("/profile"),
    SHARED_EXERCISES("/shared-exercises"),
    SLIDER_STATISTICS("/slider-statistics"),
    COURSE("/course"),
    STUDENT_DUO("/duo"),
    PAYMENT("/payment")
}

data class LanguageAutoContextData(
    val entertainingLoader: ModalLoaderWithEntertainingTexts
)

val LanguageAutoContext = createContext(LanguageAutoContextData(
    entertainingLoader = ModalLoaderWithEntertainingTexts({}, {})
))

val useLanguageAutoContext =  { useContext(LanguageAutoContext) }

val LanguageAuto = FC<Props> {
    val loader = useSelector(selectLanguageAutoLoader)
    val errorModal = useSelector(selectErrorModal)
    val dispatch = useAppDispatch()

    val windowWidth = useViewport()
    val (startLoading, endLoading) = useModalLoaderWithEntertainingTexts({ dispatch(StartModalLoading(it)) }) {
        dispatch(EndModalLoading())
    }
    val (openPromoCodePopup, setOpenPromoCodePopup) = useState(false)

    val auth = checkAuth()

    useEffect(auth) {
        GlobalScope.launch {
            val userProfile = getUserProfileRequest().data ?: return@launch

            dispatch(SetUserProfile(userProfile))
            if (userProfile.tariffPlan.uppercase() != "BASIC") {
                return@launch
            }

            val numberOfGenerations = getNumberOfGenerations() ?: return@launch
            if (numberOfGenerations < 1) {
                return@launch
            }

            setOpenPromoCodePopup(true)
        }
    }
    ViewportContext.Provider {
        value = windowWidth
        LanguageAutoContext.Provider {
            value = LanguageAutoContextData(
                entertainingLoader = ModalLoaderWithEntertainingTexts(startLoading, endLoading)
            )
            main {
                css(page(windowWidth))
                ResponsibleAdminPanel {}
                Routes {
                    Route {
                        path = ERoute.EXERCISES.path
                        element = PrivateExercises.create()
                    }
                    Route {
                        path = ERoute.EXERCISE.path
                        element = Generate.create()
                    }
                    Route {
                        path = "${ERoute.EXERCISE.path}/:request_id"
                        element = Generate.create()
                    }
                    Route {
                        path = ERoute.SETTINGS.path
                        element = VFC {
                            ExerciseSettings {}
                        }.create()
                    }
                    Route {
                        path = ERoute.COMMUNITY.path
                        element = PublicExercises.create()
                    }
                    Route {
                        path = ERoute.SHARED_EXERCISES.path
                        element = SharedExercises.create()
                    }
                    Route {
                        path = ERoute.PROFILE.path
                        element = UserProfileToEdit.create()
                    }
                    Route {
                        path = "${ERoute.PROFILE.path}/:id"
                        element = UserProfileToView.create()
                    }
                    Route {
                        path = "${ERoute.SLIDER_STATISTICS.path}/:requestId"
                        element = SliderStatistics.create()
                    }
                    Route {
                        path = ERoute.COURSE.path
                        element = Course.create()
                    }
                    Route {
                        path = "/*"
                        element = Redirect.create()
                    }
                }
            }
            PromoCodePopup {
                open = openPromoCodePopup
                fromLanguageAuto = true
            }
            LanguageAutoModalLoader {
                loading = loader.loading
                text = loader.text
                showSuccessfulLoading = loader.showSuccessfulLoading
                subText = loader.subText
                onClose = { dispatch(EndModalLoading()) }
            }
            ErrorModal {
                isOpen = errorModal.open
                title = errorModal.title
                text = errorModal.text
                onClose = { dispatch(CloseErrorModal()) }
            }
        }
    }
}

val Generate = VFC {
    ProtectedRoute {
        section {
            css(exercise)
            LanguageAutoContent {}
            LanguageAutoSlider {}
        }
    }
}

val Redirect = VFC {
    val navigate = useNavigate()

    useEffectOnce {
        GlobalScope.launch {
            val tokenValidation = validateToken()
            if (tokenValidation.code != 200) {
                navigate(to = ERoute.COMMUNITY.path)
            } else {
                navigate(to = ERoute.EXERCISES.path)
            }
        }
    }
}
