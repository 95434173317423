package online.interactiver.common.autogeneration

import kotlinx.serialization.Serializable

@Serializable
data class SliderAutoGenerationFolder(
    val id: Int,
    val name: String,
    val ownerId: Int,
    val parentFolderId: Int?,
    val interactivesGroupId: Int?,
    val role: String? = null
) {
    fun asExercise(): LanguageAutoGeneration = LanguageAutoGeneration(
        requestId = id,
        time = "",
        name = name,
        contentType = "Folder",
        languageLevel = "",
        isPublic = false,
        isUserOwns = true,
        ownerId = ownerId,
        parentFolderId = parentFolderId,
        role = role
    )
}
