package online.interactiver.common.interactivepicture

import kotlinx.serialization.Serializable
import online.interactiver.common.math.LinearTransformation

@Serializable
data class Picture(
    var base64: String? = null, // sample  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=="
    //var svg: String? = null, - also saved in base64 // sample "<g><rect width=\"100\" height=\"50\" fill=\"#f06\"></rect></g>"

    var source: Source? = null, // to save and render name
) {
    fun clone() = copy()
}


@Serializable
data class Background(
    var picture: Picture? = null, // use it if exists
    var width: Int? = null,
    var height: Int? = null,
    var maxWidth: Int? = 800,
) {
    fun clone() = copy()

    // linear transformation is not applied because scaling determined by background sizes
}


@Serializable
data class SolvingStageCondition(
    // OR  // Not enum because several options can be switched (even now: whileSolving, afterVerification, always)
    var activeBeforeSolvingStart: String? = null, // "1" if active
    var activeWhileSolving: String? = null, // "1" if active
    var activeAfterFirstVerification: String? = null, // "1" if active
    var activeAfterFinalVerification: String? = null, // "1" if active
    //LATER: var custom: String? = null // condition on js can use variables like "attemptNumber" or "previousMistakes"
) {
    fun clone() = copy()
}


@Serializable
data class ShowCondition( // AND
    var solvingStageCondition: SolvingStageCondition,
    var scoreConditions: List<ScoreCondition>? = null
    //Maybe later conditions based on chosen in selectors or on pressed buttons or other sections
) {
    fun clone() = copy(
        solvingStageCondition = solvingStageCondition.clone(),
        scoreConditions = ArrayList(scoreConditions ?: arrayListOf())
    )

    fun clone(scoreConditions: List<ScoreCondition>) = copy(
        solvingStageCondition = solvingStageCondition.clone(),
        scoreConditions = scoreConditions
    )
}


@Serializable
data class DependentText(
    val type: String = "DependentText",
    // formulas linked on other elements (inputs, buttons, ...) state // later
) {
    fun clone() = copy()
}

@Serializable
data class TextFrame(
    //will be changed after research
    // first stage: constructor allows only text without styles
    var simpleText: String? = null, // no html
    var style: TextStyle? = null,

    // second stage: constructor allows flexible text in 'htmlEditView', and converts with satori to svg, then to base64 - and save in picture
    var type: String = "EditHtmlRenderSvg",
    var htmlEditView: String? = null, // to give opportunities to continue editing
    var renderView: Picture? = null, // to render in generator
    // var fontsFile? // to save fonts to input in satori

    // var dependentText: DependentText = null // formulas linked on other elements (inputs, buttons, ...) state // later
) {
    fun clone() = copy(
        style = style?.clone()
    )

    fun applyTransformation(linearTransformation: LinearTransformation) {
        style?.applyTransformation(linearTransformation)
    }
}

@Serializable
data class SimpleElement(
    val type: String = "MainElement", // "Hint", "GapDragTo"
    val identifier: Identifier = Identifier(), //
    var showCondition: ShowCondition? = null, //always shown if null

    var geometry: Geometry? = null,
    var text: TextFrame? = null, // can be rendered above other element kinds
    var picture: Picture? = null, // geometry can specify it's format (it could be emplaced in rect or later in other geometry using fillPatternImage & fillPatternOffset)
    var link: String? = null,
    //later add video, gif, ...

    var style: ElementStyle? = null,
) {
    fun clone() = copy(
        identifier = identifier.clone(),
        showCondition = showCondition?.clone(),
        geometry = geometry?.clone(),
        text = text?.clone(),
        picture = picture?.clone(),
        style = style?.clone(),
    )

    fun clone(linearTransformation: LinearTransformation) = clone().apply {
        applyTransformation(linearTransformation)
    }

    fun applyTransformation(linearTransformation: LinearTransformation) {
        geometry?.applyTransformation(linearTransformation)
        text?.applyTransformation(linearTransformation)
    }
}