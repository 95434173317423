package online.interactiver.common.enums

// rename to ETargetSkill?
enum class EContentType(
    val value: String, val handleAllFormat: Boolean, val uiValue: String,
    val icon: String = "", val description: String = "", val isBeta: Boolean = true
) {
    VOCABULARY_WORDS(
        value = "Vocabulary Words",
        handleAllFormat = true,
        uiValue = "Vocabulary",
        icon = "ic_bookmark_24x24.svg",
        description = "Word-matching and sentence-building exercises tailored to your vocabulary, text, or chosen topic",
        isBeta = false
    ), // new VOCABULARY BY VOCABULARY (and optional TOPIC)
    EXERCISES_FROM_CONTENT(
        value = "Good from any",
        handleAllFormat = true,
        uiValue = "Text",
        icon = "ic_book_bookmark_24x24.svg",
        description = "Word-matching and sentence-building exercises only from words in your content",
        isBeta = false
    ), // new GRAMMAR BY TOPIC; // new LISTENING_BY_TEXT (and optional VOCABULARY)
    GRAMMAR(
        value = "Grammar",
        handleAllFormat = true,
        uiValue = "Grammar",
        icon = "ic_note_pencil_24x24.svg",
        description = "Sentence-building and matching exercises customized to chosen Grammar topic"
    ), // new GRAMMAR BY TOPIC
    LISTENING_BY_TEXT(
        value = "Listening by text",
        handleAllFormat = true,
        uiValue = "Listening",
        icon = "ic_headphones_24x24.svg",
        description = "Enhance your students’ listening skills with engaging audio material and exercises"
    ),
    STORYTELLING(
        value = "Storytelling",
        handleAllFormat = true,
        uiValue = "Storytelling",
        icon = "ic_book_open_24x24.svg",
        description = "Introduce your students to exercises in engaging stories"
    ), // new TEXT BY TEXT (and optional VOCABULARY)
    PRE_MADE_CONTENT(
        value = "Pre-made Content",
        handleAllFormat = true,
        uiValue = "Pre-made content",
        icon = "ic_book_bookmark_24x24.svg",
        description = "Use your existing text materials to create interactive exercises that boost engagement"
    ), // new custom text without openAI that available not on basic tariff
    PREFERRED_TOPIC(
        value = "Preferred Topic",
        handleAllFormat = false,
        uiValue = "Topic"
    ), // new TEXT BY TOPIC (and optional VOCABULARY)
    LISTENING_BY_TOPIC(
        value = "Listening by topic",
        handleAllFormat = false,
        uiValue = "Listening"
    ); // new LISTENING_BY_TOPIC (and optional VOCABULARY)

    companion object  {
        fun fromString(str: String?): EContentType? {
            return entries.find { it.value == str }
        }
    }
}

enum class EContentForm(
    val value: String, val uiValue: String
) {
    ENGAGING_STORY (
        "ENGAGING_STORY",
        "Engaging story"
    ),
    FIRST_PERSON_DIALOGUE (
        "FIRST_PERSON_DIALOGUE_BY_ROLES",
        "First person dialogue"
    ),
    CUSTOMER_SERVICE_DIALOGUE (
        "CUSTOMER_SERVICE_DIALOGUE_BY_ROLES",
        "Customer service dialogue"
    );
    companion object  {
        fun fromString(str: String?): EContentForm? {
            return EContentForm.entries.find { it.value == str }
        }
    }
}