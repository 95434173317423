package widgets.DuoCourses.ui.DuoCourse

import antd.Button
import app.useAppDispatch
import emotion.react.css
import entities.errorModal.store.OpenErrorModal
import features.UndoAlert.store.UndoAlertState
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.timers.setTimeout
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.EExerciseState
import org.w3c.dom.HTMLButtonElement
import pages.constructor.ui.components.elements.blackFilter
import pages.studentDuo.DeleteDuoCourse
import pages.studentDuo.SetUndoAlert
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.router.useNavigate
import react.router.useParams
import shared.components.Icon
import widgets.DuoCourses.ui.deleteCourseRequest
import kotlin.time.Duration.Companion.seconds

external interface DuoCourseProps : Props {
    var course: DuoCourse
    var onDelete: () -> Unit
    var close: () -> Unit
}

val DuoCourse = FC<DuoCourseProps> { props ->
    val navigate = useNavigate()
    val dispatch = useAppDispatch()
    val pathParams = useParams()
    val folderId = pathParams["folder_id"]?.toIntOrNull()

    div {
        css(course)
        onClick = {
            navigate("/duo/folder/${props.course.id}")
            props.close()
        }
        div {
            css(container)
            p {
                css(name)
                +props.course.name
            }
            div {
                css(buttonsContainer)
                Button {
                    css(iconButton)
                    Icon {
                        src = "arrow_right_filled_12x16.svg"
                    }
                }
                Button {
                    css(iconButton)
                    Icon {
                        css(blackFilter)
                        src = "ic_delete_16x18.svg"
                    }
                    onClick = { evt: MouseEvent<HTMLButtonElement, *> ->
                        evt.stopPropagation()
                        val timeoutId = setTimeout(10.seconds) {
                            dispatch(SetUndoAlert(null))
                            GlobalScope.launch {
                                val deleted = deleteCourseRequest(props.course.id)
                                if (!deleted) {
                                    dispatch(OpenErrorModal("Error deleting course"))
                                    return@launch
                                }

                                dispatch(DeleteDuoCourse(props.course.id))
                                props.onDelete()
                                if (folderId != props.course.id) {
                                    return@launch
                                }

                                navigate("/duo/content")
                            }
                        }
                        dispatch(SetUndoAlert(UndoAlertState(
                            timeoutId,
                            "Deleting course ${props.course.name}"
                        )))
                    }
                }
            }
        }
        div {
            css(bar)
            div {
                val progressPercent = props.course.states.count {
                    it.state == EExerciseState.SOLVED.value
                } * 100.0 / props.course.states.size
                css(progress(progressPercent))
            }
        }
    }
}