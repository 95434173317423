package pages.studentDuo

import entities.errorModal.store.ErrorModalAction
import entities.errorModal.store.ErrorModalReducer
import entities.errorModal.store.ErrorModalState
import entities.modalLoader.ModalLoaderAction
import entities.modalLoader.ModalLoaderReducer
import entities.modalLoader.ModalLoaderState
import entities.solvedTasks.ui.DaysWithTasks
import features.UndoAlert.store.UndoAlertState
import kotlinx.js.timers.Timeout
import online.interactiver.common.course.CourseState
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.PrevExercise
import online.interactiver.common.enums.EContentForm
import online.interactiver.common.user.UserProfile
import redux.RAction
import redux.createStore
import redux.rEnhancer
import widgets.UserProfile.UserProfileAction
import widgets.UserProfile.UserProfileReducer

data class StudentDuoStoreState(
    val course: CourseState = CourseState(),
    val duoCourses: List<DuoCourse> = listOf(),
    val loader: ModalLoaderState = ModalLoaderState(false, ""),
    val errorModalState: ErrorModalState = ErrorModalState(false),
    val userProfile: UserProfile? = null,
    val numberOfUsedGenerations: Int? = null,
    val prevExercise: PrevExercise? = null,
    val daysWithTasks: DaysWithTasks? = null,
    val coursesWereFetched: Boolean = false,
    val undoAlertState: UndoAlertState? = null
)

data class SetStudentDuoDaysWithTasks(val daysWithTasks: DaysWithTasks) : RAction

data class SetStudentDuoLanguageToLearn(val languageToLearn: String) : RAction

data class SetStudentDuoInterfaceLanguage(val interfaceLanguage: String) : RAction

data class SetStudentDuoTextOrTopic(val textOrTopic: String) : RAction

data class SetStudentDuoNumberOfUsedGenerations(val numberOfUsedGenerations: Int) : RAction

data class SetStudentDuoPrevExercise(val prevExercise: PrevExercise) : RAction
data class SetStudentDuoContentForm(val contentForm: EContentForm) : RAction
data class SetDuoCourses(val duoCourses: List<DuoCourse>) : RAction
data class SetDuoCoursesWereFetched(val coursesWereFetched: Boolean) : RAction
data class DeleteDuoCourse(val id: Int): RAction
data class SetUndoAlert(val undoAlertState: UndoAlertState?): RAction

private val deleteDuoCourse = { courses: List<DuoCourse>, id: Int ->
    courses.filter { it.id != id }
}

fun studentDuoReducer(state: StudentDuoStoreState, action: RAction): StudentDuoStoreState {
    return when (action) {
        is SetUndoAlert -> state.copy(
            undoAlertState = action.undoAlertState
        )
        is DeleteDuoCourse -> state.copy(
            duoCourses = deleteDuoCourse(state.duoCourses, action.id)
        )
        is SetDuoCoursesWereFetched -> state.copy(
            coursesWereFetched = action.coursesWereFetched
        )
        is SetDuoCourses -> state.copy(
            duoCourses = action.duoCourses
        )
        is SetStudentDuoPrevExercise -> state.copy(
            prevExercise = action.prevExercise
        )
        is SetStudentDuoNumberOfUsedGenerations -> state.copy(
            numberOfUsedGenerations = action.numberOfUsedGenerations
        )
        is SetStudentDuoLanguageToLearn -> state.copy(
            course = state.course.copy(
                languageToLearn = action.languageToLearn
            )
        )
        is SetStudentDuoInterfaceLanguage -> state.copy(
            course = state.course.copy(
                interfaceLanguage = action.interfaceLanguage
            )
        )
        is SetStudentDuoTextOrTopic -> state.copy(
            course = state.course.copy(
                text = action.textOrTopic
            )
        )
        is SetStudentDuoContentForm -> state.copy(
            course = state.course.copy(
                contentForm = action.contentForm
            )
        )
        is ModalLoaderAction -> state.copy(
            loader = ModalLoaderReducer(state.loader, action)
        )
        is ErrorModalAction -> state.copy(
            errorModalState = ErrorModalReducer(state.errorModalState, action)
        )
        is UserProfileAction -> state.copy(userProfile = UserProfileReducer(state.userProfile, action))
        is SetStudentDuoDaysWithTasks -> state.copy(daysWithTasks = action.daysWithTasks)
        else -> state
    }
}

val selectUndoAlert = { state:StudentDuoStoreState ->
    state.undoAlertState
}

val selectStudentDuoCoursesWereFetched = { state: StudentDuoStoreState ->
    state.coursesWereFetched
}

val selectStudentDuoCourses = { state: StudentDuoStoreState ->
    state.duoCourses
}

val selectStudentDuoDaysWithTasks = { state: StudentDuoStoreState ->
    state.daysWithTasks
}

val selectStudentDuoPrevExercise = { state: StudentDuoStoreState ->
    state.prevExercise
}

val selectStudentDuoState = { state: StudentDuoStoreState ->
    state.course
}

val selectStudentDuoUserProfile = { state: StudentDuoStoreState ->
    state.userProfile
}

val selectStudentDuoNumberOfUsedGenerations = { state: StudentDuoStoreState ->
    state.numberOfUsedGenerations
}

val selectStudentDuoErrorModal = { state: StudentDuoStoreState ->
    state.errorModalState
}

val selectStudentDuoLoader = { state: StudentDuoStoreState ->
    state.loader
}

val selectStudentDuoErrorType = { state: StudentDuoStoreState ->
    selectStudentDuoErrorModal(state).type
}

val studentDuoStore = createStore(
    reducer = ::studentDuoReducer,
    preloadedState = StudentDuoStoreState(),
    enhancer = rEnhancer()
)