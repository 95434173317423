package widgets.LanguageAutoContent.ui

import csstype.*
import utils.types.CssStyle
import utils.types.shadowArray

val widget: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 26.px
    width = 100.pct
    maxWidth = 960.px
    padding = 40.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val header: CssStyle = {
    display = Display.flex
    width = 100.pct
    justifyContent = JustifyContent.spaceBetween
}

val account: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 8.px
    justifyContent = JustifyContent.center
    backgroundColor = Color("")
    color = Color("")
}

val languages: CssStyle = {
    display = Display.flex
    width = 100.pct
    justifyContent = JustifyContent.spaceBetween
}

val toLearnAndLevelContainer: CssStyle = {
    display = Display.flex
    gap = (18.0 / 530 * 100).pct
    width = 530.px
}

val generateButton: CssStyle = {
    color = Color("white")
    backgroundColor = Color("#597EF7")
    borderRadius = 6.px
    border = None.none
    fontWeight = FontWeight.bold
    lineHeight = 20.8.px
    fontSize = 16.px
    fontFamily = string("\"Inter\", system-ui")
    width = 250.px
    height = 44.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 13.px
    hover {
        backgroundColor = Color("#3E62D9")
        color = important(Color("white"))
    }
}

val parentFolderNameHeaderLabel: CssStyle = {
    fontSize = 24.px;
    color = rgb(40, 47, 62);
    fontWeight = FontWeight.bold;
}

val parentFolderNameHeaderLabelContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    gap = 12.px
    alignItems = AlignItems.center
    justifyContent = JustifyContent.stretch
}
