package widgets.DuoCourses.ui

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import jsonClient
import utils.getToken

suspend fun deleteCourseRequest(folderId: Int): Boolean {
    return try {
        val response = jsonClient.delete("/api/v2/student-duo/course/$folderId") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        response.status.value == 200
    } catch (e: ResponseException) {
        false
    }
}