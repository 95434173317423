package pages.studentDuoRoadMap

import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import jsonClient
import kotlinx.serialization.json.Json
import online.interactiver.common.duo.DuoCourse
import utils.getToken

suspend fun getDuoCourseRequest(folderId: Int): DuoCourse? {
    return try {
        val response = jsonClient.get("api/v2/student-duo/course/${folderId}") {
            bearerAuth(getToken())
            contentType(ContentType.Application.Json)
        }

        if (response.status.value != 200) {
            return null
        }

        Json.decodeFromString(response.bodyAsText())
    } catch (e: ResponseException) {
        null
    }
}