package pages.studentDuoRoadMap.ui.StudentDuoRoadMap

import app.useAppDispatch
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import online.interactiver.common.duo.DuoCourse
import online.interactiver.common.duo.EExerciseState
import pages.studentDuo.SetDuoCourses
import pages.studentDuo.selectStudentDuoCourses
import pages.studentDuo.selectStudentDuoCoursesWereFetched
import pages.studentDuo.selectStudentDuoUserProfile
import pages.studentDuoRoadMap.getDuoCourseRequest
import react.redux.useSelector
import react.router.useNavigate
import react.router.useParams
import react.useEffect

val useDuoCourse = { setCourse: (DuoCourse) -> Unit ->
    val pathParams = useParams()
    val navigate = useNavigate()
    val dispatch = useAppDispatch()
    val courses = useSelector(selectStudentDuoCourses)
    val coursesWereFetched = useSelector(selectStudentDuoCoursesWereFetched)
    val userProfile = useSelector(selectStudentDuoUserProfile)

    val folderId = pathParams["folder_id"]?.toIntOrNull()

    useEffect(folderId, coursesWereFetched, userProfile?.hashCode()) {
        if (!coursesWereFetched || userProfile == null) {
            return@useEffect
        }

        if (folderId == null) {
            if (courses.isEmpty()) {
                navigate("/duo/content")
                return@useEffect
            }

            navigate("/duo/folder/${courses.last().id}")
            return@useEffect
        }

        GlobalScope.launch {
            val courseResponse = getDuoCourseRequest(folderId)
            if (courseResponse == null || courseResponse.states.isEmpty()) {
                navigate("/duo/content")
                return@launch
            }
            val course = courses.singleOrNull { it.id == folderId }
            val newCourses = if (course == null) {
                courseResponse.states.first().state = EExerciseState.CURRENT.value
                listOf(courseResponse) + courses
            } else {
                var i = 0
                var state: String? = course.states.first().state
                while (state != null
                    && courseResponse.states.getOrNull(i)?.id == course.states.getOrNull(i)?.id
                ) {
                    courseResponse.states.getOrNull(i)?.state = state
                    i += 1
                    state = course.states.getOrNull(i)?.state
                }

                courses.map { if (it.id == courseResponse.id) courseResponse else it }
            }
            localStorage.setItem("duo_courses", Json.encodeToString(newCourses))
            dispatch(SetDuoCourses(newCourses))
            setCourse(courseResponse)
        }
    }
}
