package widgets.StudentDuoSlider.ui

import csstype.*
import utils.types.CssStyle
import utils.types.shadowArray

val studentDuoSlider: CssStyle = {
    width = 100.pct
    maxWidth = 511.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
    height = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.spaceBetween
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    padding = 20.px
    gap = 28.px
    boxSizing = BoxSizing.borderBox
}

val iFrameContainer: (Int) -> CssStyle = {
    {
        height = minOf(669.0, it * 1.3).px
        width = minOf(510, it).px
        display = Display.flex
        gap = 10.px
        justifyContent = JustifyContent.center
        alignSelf = AlignSelf.center
    }
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    width = 100.pct
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
}
