package pages.payment.ui.PromoCode

import antd.Button
import antd.Input
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import pages.payment.getDiscount
import pages.payment.ui.Payment.PromoCodeMeta
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.useState
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton

external interface PromoCodeProps : Props {
    var onChange: (PromoCodeMeta?) -> Unit
    var discount: Int?
}

val PromoCode = FC<PromoCodeProps> { props ->
    val (promoCode, setPromoCode) = useState("")
    val (isError, setIsError) = useState(false)

    div {
        p {
            css(label)
            +"Promo code"
        }
        div {
            css(horizontalContainer)
            Input {
                value = promoCode
                onInput = {
                    setPromoCode(it.currentTarget.value)
                }
                placeholder = "Enter code"
            }
            Button {
                css(generateButton)
                onClick = {
                    GlobalScope.launch {
                        val discount = getDiscount(promoCode)
                        if (discount == null) {
                            props.onChange(null)
                            setIsError(true)
                            return@launch
                        }

                        setIsError(false)
                        props.onChange(PromoCodeMeta(promoCode, discount))
                    }
                }
                +"Apply"
            }
        }
        if (isError) {
            p {
                css(label.extend(error))
                +"Invalid promo code"
            }
        } else if (props.discount != null) {
            p {
                css(label)
                +"Discount ${props.discount}%"
            }
        }
    }
}