package widgets.StudentDuoContent.ui

import antd.Button
import antd.Option
import antd.Select
import app.useAppDispatch
import csstype.*
import emotion.react.css
import entities.errorModal.store.EErrorType
import entities.errorModal.store.OpenErrorModal
import entities.errorModal.store.RemoveErrorType
import entities.modalLoader.EndModalLoading
import entities.modalLoader.StartModalLoading
import entities.modalLoader.useModalLoaderWithEntertainingTexts
import entities.solvedTasks.ui.SolvedTasks
import enums.EButtonTypes
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import online.interactiver.common.enums.EContentForm
import online.interactiver.common.enums.ELanguage
import pages.constructor.ui.components.elements.lightBlueFilter
import pages.studentDuo.*
import pages.studentDuo.ui.components.UsedGenerations.NUMBER_OF_FREE_ATTEMPTS
import pages.studentDuo.ui.components.UsedGenerations.UsedGenerations
import react.VFC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.section
import react.redux.useSelector
import react.router.dom.useSearchParams
import react.router.useNavigate
import shared.components.Icon
import shared.components.ProtectedRoute.ProtectedRoute
import shared.components.header.Header
import utils.types.extend
import widgets.LanguageAutoContent.ui.components.ContentCard.ContentCard
import widgets.LanguageAutoContent.ui.components.SelectLanguage.SelectLanguage
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoContent.generateStudentDuoCourse
import widgets.StudentDuoContent.ui.TopicTextArea.TopicTextArea
import widgets.StudentDuoContent.useFocusIf

enum class EStudentDuoSearchParams(val value: String) {
    INTERFACE_LANGUAGE("interface_language"),
    LANGUAGE_TO_LEARN("language_to_learn")
}

private data class TopicMeta(val topic: String, val icon: String = "")

private val topics = listOf(
    TopicMeta(topic = "Bank credit limit increase, future time", icon = "ic_money_dollar_circle_line_24x24.svg"),
    TopicMeta(topic = "Ask for a refund on a purchase", icon = "ic_money_dollar_circle_line_24x24.svg"),
    TopicMeta(topic = "Report a lost credit card, past time", icon = "ic_money_dollar_circle_line_24x24.svg"),
//    TopicMeta(topic = "Dialogue about prescription extension", icon = "ic_newspaper_line_24x24.svg"),
    TopicMeta(topic = "I want to raise my salary", icon = "ic_newspaper_line_24x24.svg"),
//    TopicMeta(topic = "I want to raise my salary, if then", icon = "ic_newspaper_line_24x24.svg"),
    TopicMeta(topic = "Where is my pizza - dialog with courier", icon = "ic_pizza_24x24.svg"),
    TopicMeta(topic = "How to buy tickets - basic level", icon = "ic_book_bookmark_24x24.svg"),
    TopicMeta(topic = "Courier brought wrong chair, change it", icon = "ic_book_bookmark_24x24.svg"),
//    TopicMeta(topic = "Traveling, advanced level of the language", icon = "ic_bookmark_24x24.svg")
).shuffled().take(2)

val StudentStoryContent = VFC {
    val state = useSelector(selectStudentDuoState)
    val userProfile = useSelector(selectStudentDuoUserProfile)
    val numberOfUsedGenerations = useSelector(selectStudentDuoNumberOfUsedGenerations)
    val prevExercise = useSelector(selectStudentDuoPrevExercise)

    val errorType = useSelector(selectStudentDuoErrorType)

    val shouldMakeLanguageToLearnRed = errorType == EErrorType.EMPTY_LANGUAGE_TO_LEARN

    val dispatch = useAppDispatch()

    val (searchParams, setSearchParams) = useSearchParams()

    val (startLoading, endLoading) = useModalLoaderWithEntertainingTexts({ dispatch(StartModalLoading(it))}) {
        dispatch(EndModalLoading())
    }

    useConfirmPayment()
    useDuoSearchParams()
    useFocusIf(state.languageToLearn != null, EButtonTypes.DUO_CONTENT_TEXTAREA.value)

    val navigate = useNavigate()

    ProtectedRoute {
        toStudentDuo = true
        section {
            css(content)
            SolvedTasks {}
            div {
                css(pageContainer)
                div {
                    css(languagesContainer)
                    SelectLanguage {
                        label = "Language to learn"
                        value = state.languageToLearn
                        options = ELanguage.entries.map { it.text }
                        makeRed = shouldMakeLanguageToLearnRed
                        this.onChange = {
                            if (shouldMakeLanguageToLearnRed) {
                                dispatch(RemoveErrorType())
                            }

                            dispatch(SetStudentDuoLanguageToLearn(it))
                            searchParams.set(EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value, it)
                            setSearchParams(searchParams)
                            localStorage.setItem("duo_${EStudentDuoSearchParams.LANGUAGE_TO_LEARN.value}", it)
                        }
                    }
                    SelectLanguage {
                        label = "Native language"
                        value = state.interfaceLanguage
                        options = ELanguage.entries.map { it.text }
                        this.onChange = {
                            dispatch(SetStudentDuoInterfaceLanguage(it))
                            searchParams.set(EStudentDuoSearchParams.INTERFACE_LANGUAGE.value, it)
                            setSearchParams(searchParams)
                            localStorage.setItem("duo_${EStudentDuoSearchParams.INTERFACE_LANGUAGE.value}", it)
                        }
                    }
                }
//                Header {
//                    text = "Specify language area you want to practice"
//                    fontSize = 1.25.rem
//                    lineHeight = 1.625.rem
//                }

//                div {
//                    css(wrappedFlex)
//                    topics.forEach {
//                        ContentCard {
//                            contentType = it.topic
//                            header = it.topic
//                            onClick = {
//                                dispatch(SetStudentDuoTextOrTopic(it.topic))
//                            }
//                            icon = it.icon
//                        }
//                    }
//                }
                TopicTextArea {
                    value = state.text
                    this.onChange = {
                        dispatch(SetStudentDuoTextOrTopic(it))
                    }
                }
                Select {
                    value = state.contentForm.uiValue
                    onChange = { _, option ->
                        EContentForm.fromString(option.label.toString())?.let {
                            dispatch(SetStudentDuoContentForm(it))
                        }
                    }
                    EContentForm.entries.forEach { option ->
                        Option {
                            label = option.value
                            value = option.uiValue
                        }
                    }
                }

                Button {
                    css(generateButton.extend {
                        width = Auto.auto
                    })
                    Icon {
                        src = "ic_magic_wand_24x24.svg"
                    }
                    disabled =
                        userProfile?.tariffPlan?.uppercase() == "BASIC" && (numberOfUsedGenerations == null || numberOfUsedGenerations >= NUMBER_OF_FREE_ATTEMPTS)
                    onClick = f@{
                        if (state.languageToLearn == null) {
                            dispatch(
                                OpenErrorModal(
                                    title = "Please select the language to learn",
                                    type = EErrorType.EMPTY_LANGUAGE_TO_LEARN
                                )
                            )
                            return@f
                        }
                        startLoading()
                        GlobalScope.launch {
                            val response = generateStudentDuoCourse(state)
                            endLoading()
                            if (response.data == null) {
                                dispatch(
                                    OpenErrorModal(
                                        title = "Error generating course"
                                    )
                                )
                                return@launch
                            }

                            dispatch(SetStudentDuoNumberOfUsedGenerations((numberOfUsedGenerations ?: 0) + 1))

                            navigate("/duo/folder/${response.data}")
                        }
                    }
                    +"Do the magic"
                }
                if (prevExercise != null) {
                    Button {
                        css(newTaskButton.extend {
                            width = 100.pct
                        })
                        disabled =
                            userProfile?.tariffPlan?.uppercase() == "BASIC" && (numberOfUsedGenerations == null || numberOfUsedGenerations >= NUMBER_OF_FREE_ATTEMPTS)
                        Icon {
                            css(lightBlueFilter)
                            src = "ic_magic_wand_24x24.svg"
                        }
                        onClick = {
                            startLoading()
                            GlobalScope.launch {
                                val response = generateStudentDuoByPrevExerciseRequest(prevExercise.id)
                                endLoading()
                                if (response.data?.slider == null || response.data.requestId == null) {
                                    return@launch
                                }

                                dispatch(SetStudentDuoNumberOfUsedGenerations((numberOfUsedGenerations ?: 0) + 1))

                                dispatch(StartModalLoading("Painting exercise..."))
                                navigate("/duo/exercise/${response.data.requestId}")
                            }
                        }
                        +"Back to previous topic"
                    }
                }

                UsedGenerations {}
            }
        }
    }
}