package pages.googleAuth.ui

import csstype.AlignSelf
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.browser.window
import react.VFC
import react.dom.html.AnchorTarget
import react.dom.html.ButtonType
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.main
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import react.router.dom.useSearchParams
import react.router.useLocation
import shared.components.Icon

val GoogleAuth = VFC {
    val (searchParams) = useSearchParams()
    val location = useLocation()

    val fromStudentDuo = searchParams.get("duo")?.toBooleanStrictOrNull()
    val headerText = if (fromStudentDuo == true) {
        "Boost your language\nwith adaptive game-like exercises"
    } else {
        "Create interactive language\nlearning exercises in one minute"
    }
    main {
        css(page)
        section {
            css(auth)
            Icon {
                css(googleIcon)
                src = "ic_interactive_38x35.svg"
            }
            div {
                css(container)
                div {
                    css(authContainer)
                    h1 {
                        css(header)
                        +headerText
                    }
                    form {
                        css(formCss)
                        action = "${window.location.origin}/google${if (fromStudentDuo == true) "/duo" else "/language-auto"}/login"
                        method = "get"
                        button {
                            css(buttonCss)
                            type = ButtonType.submit
                            Icon {
                                src = "ic_google_icon_24x25.svg"
                            }
                            onClick = {
                                localStorage.setItem("previous_page", location.state.asDynamic().from)
                            }
                            +"Continue with Google"
                        }
                    }
                }
                div {
                    css {
                        alignSelf = AlignSelf.flexStart
                    }
                    p {
                        css(documents)
                        +"By continuing, you agree to our "
                        a {
                            css(link)
                            href = "https://inream.com/terms_of_service"
                            target = AnchorTarget._blank
                            +"Term of Use"
                        }
                        +" and "
                        a {
                            css(link)
                            target = AnchorTarget._blank
                            href = "https://inream.com/privacy_policy"
                            +"Privacy Policy."
                        }
                    }
                    p {
                        css(documents)
                        +"We use AI and cookies to provide the best site experience."
                    }
                }
            }
        }
    }
}